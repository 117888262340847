import { signal } from "@preact/signals-react";

const data = signal([]);
const isLoading = signal(false);
const errorMsg = signal("");

const MenuPage = () => {

  const fetchData = () => {
    isLoading.value = true;
    fetch('https://www.api.subursedayamaju.co.id/api/auth/test')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((jsonData) => {
        console.log(jsonData);
        if (Array.isArray(jsonData)) {
          data.value = jsonData;
          console.log(data.value);
        } else {
          throw new Error('Data is not in the expected format');
        }
      })
      .catch((error) => {
        errorMsg.value = error.message
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  return (
    <div>
      <h2>Menu Page</h2>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading.value ? 'Loading...' : 'Fetch Data'}
      </button>
      {errorMsg.value && <div>Error: {errorMsg.value}</div>}
      <ul>
        {data.value.toString()}
      </ul>
      <button onClick={()=>{
        console.log(data.value);
        data.value.map((dataItem) => (
          console.log(dataItem)
        ));
      }}>
        print
      </button>
      {data.value.map((dataItem) => (
          <li key={dataItem.id}>
            <p>ID: {dataItem.id}</p>
            <p>UUID: {dataItem.uuid}</p>
            <p>Email: {dataItem.email}</p>
            {/* Render other properties similarly */}
          </li>
        ))}
    </div>
  );
};

export default MenuPage;
