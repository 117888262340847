import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MenuPage from './pages/MenuPage';
import OrdersPage from './pages/OrdersPage';
import CheckoutPage from './pages/CheckoutPage';
import CustomerInfoPage from './pages/CustomerInfoPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import NotFoundPage from './pages/NotFoundPage';

const NewComponent = MenuPage()

const AppRoutes = () => {
  return (
      <Routes>
        <Route exact path="/" element={NewComponent} />
        <Route path="/orders" element={NewComponent} />
        <Route path="/checkout" element={CheckoutPage} />
        <Route path="/customer-info" element={CustomerInfoPage} />
        <Route path="/login" element={LoginPage} />
        <Route path="/register" element={RegisterPage} />
        <Route element={NotFoundPage} />
      </Routes>
  );
};

export default AppRoutes;
